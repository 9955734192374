import * as InternalIllustrations from './src/Illustrations/Illustrations';

export * as Animations from './src/Animations';

export { Icons } from '@ifood/fits-crafts';

export const Illustrations = { ...InternalIllustrations };

export * as LogoIfoodBeneficios from './src/Logos/IfoodBeneficios';
export * as LogoIfoodBeneficiosShort from './src/Logos/IfoodBeneficiosShort';
